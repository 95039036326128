import type { OfflineStorage } from "@greentrails/api/offline";
import { ref, type Ref, useNuxtApp } from "#imports";
import { toast } from "package:/components/elements/Toasts";
import { ERROR_CODE, error, info } from "package:/utils";
import { useTranslations } from "package:/composables/useTranslations";
import { useNetwork } from "~/composables/useNetwork";

/**
 * Composable to access the store
 */
export class OfflineStorageAccessor {
  list = ref<string[]>([]);

  states = ref(
    new Map<
      string,
      {
        status: string;
        progress: number;
        date: Date;
        error: boolean;
      }
    >(),
  );

  includes(id: string) {
    return this.list.value.includes(id);
  }

  date(id: string) {
    return this.states.value.get(id)?.date;
  }

  status(id: string) {
    const state = this.states.value.get(id);
    if (state?.error) {
      return "error";
    }
    return state?.status;
  }

  progress(id: string) {
    const state = this.states.value.get(id);
    // console.log(id, this.progresses.value[id]);
    return state?.progress;
  }

  state(id: string) {
    return this.states.value.get(id);
  }

  async pull() {
    return this.storage.list().then((list) => {
      this.list.value = list;

      for (const id of list) {
        this.storage.available(id).then((data) => {
          if (data) {
            const lastData = this.states.value.get(id);
            this.states.value.set(id, {
              status: data.downloaded ? "downloaded" : "not downloaded",
              progress:
                data.progress <= 0
                  ? data.progress
                  : // ignore any lower value than already displayed
                    lastData && lastData?.progress > data.progress
                    ? lastData?.progress
                    : data.progress,
              date: data.added,
              error: data.error,
            });
          }
        });
      }

      this.states.value = new Map(this.states.value);
    });
  }

  constructor(public storage: OfflineStorage) {
    this.pull();

    // TODO: do this better
    this.storage.onchange = () => {
      this.pull();
    };
  }

  async get(key: string) {
    return this.storage.get(key);
  }

  async add(id: string, title: string) {
    const t = useTranslations();
    const network = useNetwork();

    // cant use the this.storage.add error, since it only says "Failed to fetch", not why.
    if (!network.online) {
      toast({
        id: `offline.download.${id}`,
        variant: "error",
        message: t("offline.downloadError.noNeetwork"),
        time: 3000,
      });
      return;
    }

    const finished = this.storage.add(id);
    this.pull();

    toast({
      id: `offline.download.${id}`,
      message: t("offline.added.message", {
        area: title,
        interpolation: {
          escapeValue: false,
        },
      }),
      time: 3000,
    });

    return new Promise<void>((ok, err) => {
      finished
        .then((data) => {
          ok(data);
        })
        .catch(async (res) => {
          toast({
            id: `offline.download.${id}`,
            variant: "error",
            message: t("misc.error", { code: ERROR_CODE["offline-storage-added"] }),
            time: 3000,
          });
          error("add error", "res", res, "id", id);

          // delete from storage if unsuccessful
          await this.storage.delete(id);

          err(res);
        })
        .finally(() => {
          this.pull();
        });
    });
  }

  async delete(id: string, title: string) {
    const t = useTranslations();

    const prom = this.storage.delete(id);
    this.pull();

    return new Promise<void>((ok, err) => {
      prom
        .then((data) => {
          toast({
            id: `offline.download.${id}`,
            message: t("offline.removed.message", {
              area: title,
              interpolation: {
                escapeValue: false,
              },
            }),
            time: 3000,
          });
          ok(data);
        })
        .catch((res) => {
          toast({
            id: `offline.download.${id}`,
            variant: "error",
            message: t("misc.error", { code: ERROR_CODE["offline-storage-added"] }),
            time: 3000,
          });
          error("remove error", res);
          err(res);
        })
        .finally(() => {
          this.pull();
        });
    });
  }
}

/**
 * Composable to access the store
 */
export function useOfflineStorage() {
  if (!process.browser) return;

  const app = useNuxtApp();
  return new OfflineStorageAccessor(app.$offlineStorage);
}
